<template>
  <div style="background-color: #E0ECFF; padding: 110px 120px 109px 120px;">
    <v-container>

        <v-container>
          <div>
            <h2 class="mon-bold" style="font-size: 3vw; color: #193D6E; margin-bottom: 45px; text-align: center;">
              {{ texts.Why.Title }}
            </h2>
            <h4 class="mon" style="font-size: 1vw; font-weight: 400; line-height: 120%; color: #2D2D2D; text-align: center;">{{ texts.Why.Subtitle }}</h4>
          </div>
        </v-container>

        <div class="cards-container">

          <v-card class="custom-card">
          <div class="card-content">
            <div class="icon-container">
              <v-img src="@/assets/icons/Shield.svg" class="card-icon"></v-img>
            </div>
            <div>
              <v-card-title class="card-title">
                {{ texts.Why.TC1 }}
              </v-card-title>
            </div>
          </div>
        </v-card>

        <v-card class="custom-card">
          <div class="card-content">
            <div class="icon-container">
              <v-img src="@/assets/icons/Right-Arrow.svg" class="card-icon"></v-img>
            </div>
            <div>
              <v-card-title class="card-title">
                {{ texts.Why.TC2 }}
              </v-card-title>
            </div>
          </div>
        </v-card>

        <v-card class="custom-card">
          <div class="card-content">
            <div class="icon-container">
              <v-img src="@/assets/icons/Shield.svg" class="card-icon"></v-img>
            </div>
            <div>
              <v-card-title class="card-title">
                {{ texts.Why.TC3 }}
              </v-card-title>
            </div>
          </div>
        </v-card>

        <v-card class="custom-card">
          <div class="card-content">
            <div class="icon-container">
              <v-img src="@/assets/icons/Right-Arrow.svg" class="card-icon"></v-img>
            </div>
            <div>
              <v-card-title class="card-title">
                {{ texts.Why.TC4 }}
              </v-card-title>
            </div>
          </div>
        </v-card>

        </div>
    </v-container>

  </div>
</template>

<script>

export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "HomeFeaturedSupplierSkeletonLayout",
  data() {
    return {
      imgPlatform: require('@/assets/images/Grupo26851.png'),
      isHovered: false,
      isHoveredInsurance: false,
      isHoveredLogistic: false,
      isHoveredFinance: false,
    };
  },
};
</script>

<style scoped>

.title {
  font-size: 1.7vw;
  color: #193D6E;
  margin-bottom: 45px;
  text-align: center;
}

.subtitle {
  font-size: 24px;
  font-weight: 200;
  line-height: 120%;
  color: #2D2D2D;
  text-align: center;
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 2.5%;
  margin-top: 60px;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  height: 100%; 
}

.card-title {
  font-size: 1vw;
  font-weight: 600;
  color: #193D6E;
  margin-bottom: 15px;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;
}

.card-icon {
  width: 50px;
  height: 50px;
}

.icon-container {
  background-color: #E0ECFF;
  border-radius: 20%; 
  padding: 5%;
  margin-bottom: 10px;
}

.custom-card {
  width: 300px;
  height: 300px;
  background: #F4F4F4;
  border-radius: 27px;
  padding: 20px;
  text-align: left;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.custom-card:hover {
  transform: scale(1.05);
  background-color: rgba(244, 244, 244, 0.8);
}

</style>